<template>
   <div class="row" v-if="settings.idPermission == null">
      <div class="col-12">
         <div class="container">
            <div class="card">
               <div class="bg-banner">
                  <h1>Ecommerce <span class="mx-4 d-none d-sm-inline">|</span> {{ $t("navBar.ecommerce.settings") }}</h1>
               </div>
               <div class="card-body">
                  <div class="col-12 px-1 mb-1">
                     <h6 class="text-center mb-1">{{ $t("ecommerceSettings.permission.title") }}</h6>
                     <p class="text-center mb-0">{{ $t("ecommerceSettings.permission.desc") }}</p>
                     <hr>
                  </div>
                  <div class="d-sm-flex align-items-center w-max-content mx-auto px-1 mb-1">
                     <label class="mr-2 mb-1"><i class="far fa-lock color-theme font-13 mr-1"></i> {{ $t("ecommerceSettings.permission.perm") }}:</label>
                     <v-select title="Desktop" class="w250" :options="permissionsList" v-model="settings.permission" label="name" @input="saveSettings" />
                     <select title="Mobile" class="w250 form-control" v-model="settings.permission" @input="saveSettings">
                        <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                     </select>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="row" v-else>
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body d-flex p-10">
               <div class="col">
                  <ul class="nav nav-tabs2">
                     <li class="nav-item">
                        <a class="nav-link show active" data-toggle="tab" href="#one">
                           <i class="far fa-cog font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ecommerceSettings.buttons.settings") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#two">
                           <i class="far fa-comments-alt font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ecommerceSettings.buttons.messages") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#three">
                           <i class="far fa-money-bill-wave-alt font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ecommerceSettings.buttons.payment") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#four">
                           <i class="far fa-link font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ecommerceSettings.buttons.webhook") }}</span>
                        </a>
                     </li>
                     <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#five">
                           <i class="far fa-sticky-note font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i><span class="d-none d-md-inline">{{ $t("ecommerceSettings.buttons.purchaseTerms") }}</span>
                        </a>
                     </li>
                  </ul>
               </div>
               <div class="w-max-content">
                  <button class="btn btn-primary h-auto rounded-sm" @click="saveSettings">
                     <i class="fal fa-save font-13 mr-lg-2 d-inline d-sm-none d-lg-inline"></i><span class="d-none d-sm-inline">{{ $t("ecommerceSettings.save") }}</span>
                  </button>
               </div>
            </div>
         </div>

         <div class="tab-content mt-0">
            <div class="tab-pane show active" id="one">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-lock color-theme font-13 mr-2"></i> {{ $t("ecommerceSettings.permission.title") }} </h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("ecommerceSettings.permission.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1">
                                 <v-select title="Desktop" :options="permissionsList" v-model="settings.permission" label="name" />
                                 <select title="Mobile" class="form-control" v-model="settings.permission">
                                    <option v-for="(option, index) in permissionsList" :key="index" :value="option">{{ option.name }}</option>
                                 </select>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="card mb-2" v-if="!category.toggle">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1"><i class="far fa-file color-theme font-13 mr-2"></i> {{ $t("ecommerceSettings.categories.title") }} </h6>
                                 <p class="text-center mb-0 d-none d-xl-block">{{ $t("ecommerceSettings.categories.desc") }}</p>
                                 <hr class="d-none d-xl-block">
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <category v-for="(category, index) in settings.categories" :key="index" :index="index" :category="category" @remove="removeCategory($event)" />
                                 </div>
                              </div>
                              <div class="col-12 px-1 my-5 text-center" v-if="settings.categories.length == 0">{{ $t("ecommerceSettings.categories.noRes") }}</div>
                              <div class="col-12 px-1 mt-3 text-center" v-if="settings.categories.length < 5">
                                 <button type="button" class="btn btn-primary px-3" @click="category.toggle = true;"><i class="far fa-plus mr-1"></i> {{ $t("serverSettings.status.toggle") }}</button>
                              </div>
                           </div>   
                        </div>
                     </div>
                     <div class="card mb-0" v-else>
                        <div class="card-body">
                           <div class="col-12 px-1 mb-1">
                              <h6 class="text-center mb-1"><i class="far fa-file color-theme font-13 mr-2"></i> {{ $t("ecommerceSettings.categories.add") }} </h6>
                              <hr class="d-none d-xl-block">
                           </div>
                           <div class="col-12 px-1 mb-3">
                              <label class="mb-1"><i class="far fa-file font-12 color-theme mr-1"></i> {{ $t("ecommerceSettings.categories.addLabel") }}</label>
                              <input type="text" class="form-control" v-model="category.name" maxlength="200" />
                           </div>
                           <div class="col-12 px-1 text-right">
                              <button type="button" class="btn btn-outline-primary h-auto mr-2" @click="category.toggle = false;"><i class="far fa-undo font-11 mr-1"></i> {{ $t("serverSettings.buttons.back") }}</button>
                              <button type="button" class="btn btn-primary h-auto" @click="addCategory"><i class="far fa-check font-12 mr-1"></i> {{ $t("serverSettings.status.save") }}</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="row m-0">
                        <div class="col-sm-6 col-md-4">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-image-polaroid color-theme font-12 mr-1"></i> {{ $t("ecommerceSettings.style.logo") }}</label>
                                 <div class="w-100 text-center my-5" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic' || serverS.donatePack == 'Standard'">
                                    <span class="d-block mb-1">{{ $t("vipMessage") }}</span>
                                    <span class="d-block mb-4"><i class="far fa-star color-theme font-13 mr-2"></i>Ultimate</span>
                                    <router-link to="/Premium" class="btn btn-primary btn-lg font-16"><i class="far fa-long-arrow-right font-14 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
                                 </div>
                                 <div class="ratio ratio-1x1" v-else>
                                    <UploadImageDeck @attValor="saveStyle('shopIcon', $event)" :model="settings.shopIcon" identificador="icone" width="100%" height="100%" :minAspectRatio="1 / 1" :maxAspectRatio="1 / 1" />
                                 </div>
                              </div>
                           </div>
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-store color-theme font-11 mr-1"></i> {{ $t("ecommerceSettings.style.name") }} *</label>
                                 <input type="text" class="form-control" v-model="settings.shopName" :readonly="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic' || serverS.donatePack == 'Standard' || serverS.isAdmin" />
                                 <small class="d-block text-right mt-1" v-if="serverS.donatePack == 'Ultimate' && serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                              </div>
                           </div>
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-link color-theme font-12 mr-1"></i> {{ $t("ecommerceSettings.style.url") }} *</label>
                                 <input type="text" class="form-control" v-model="settings.shopURL" :readonly="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic' || serverS.donatePack == 'Standard' || serverS.isAdmin" />
                                 <div class="text-right mt-2">
                                    <small class="d-block mb-2" v-if="serverS.donatePack == 'Ultimate' && serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                                    <a role="button" :href="urlEcommerce + settings.shopURL" target="_blank" class="btn btn-primary"><i class="far fa-external-link-alt font-11 mr-2"></i>{{ $t("ecommerceSettings.visitStore") }}</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-sm-6 col-md-8 pr-0">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 01</label>
                                 <div class="ratio ratio-24x7">
                                    <UploadImageDeck @attValor="saveStyle('banner1', $event)" :model="settings.banner1" identificador="banner1" width="100%" height="100%" :minAspectRatio="24 / 7" :maxAspectRatio="24 / 7" />
                                 </div>
                              </div>
                           </div>
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 02</label>
                                 <div class="w-100 text-center my-5" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic' || serverS.donatePack == 'Standard'">
                                    <span class="d-block mb-1">{{ $t("vipMessage") }}</span>
                                    <span class="d-block mb-4"><i class="far fa-star color-theme font-13 mr-2"></i>Ultimate</span>
                                    <router-link to="/Premium" class="btn btn-primary btn-lg font-16"><i class="far fa-long-arrow-right font-14 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
                                 </div>
                                 <div class="w-100 text-center my-5" v-else-if="settings.banner1 == null">{{ $t("ecommerceSettings.style.bannerValidation") }}</div>
                                 <div class="ratio ratio-24x7" v-else>
                                    <UploadImageDeck @attValor="saveStyle('banner2', $event)" :model="settings.banner2" identificador="banner2" width="100%" height="100%" :minAspectRatio="24 / 7" :maxAspectRatio="24 / 7" />
                                 </div>
                              </div>
                           </div>
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label><i class="far fa-image color-theme font-12 mr-1"></i> Banner 03</label>
                                 <div class="w-100 text-center my-5" v-if="serverS.donatePack == 'Free' || serverS.donatePack == 'Basic' || serverS.donatePack == 'Standard'">
                                    <span class="d-block mb-1">{{ $t("vipMessage") }}</span>
                                    <span class="d-block mb-4"><i class="far fa-star color-theme font-13 mr-2"></i>Ultimate</span>
                                    <router-link to="/Premium" class="btn btn-primary btn-lg font-16"><i class="far fa-long-arrow-right font-14 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
                                 </div>
                                 <div class="w-100 text-center my-5" v-else-if="settings.banner2 == null">{{ $t("ecommerceSettings.style.bannerValidation") }}</div>
                                 <div class="ratio ratio-24x7" v-else>
                                    <UploadImageDeck @attValor="saveStyle('banner3', $event)" :model="settings.banner3" identificador="banner3" width="100%" height="100%" :minAspectRatio="24 / 7" :maxAspectRatio="24 / 7" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="two">
               <div class="row">
                  <div class="col-12">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <h6 class="limitador my-0 cursor-pointer" title="Edit" @click="editChannel">
                              <span>{{ $t("ecommerceSettings.selected") }}: <span class="ml-1" v-if="settings.nameChannel != null">#{{ settings.nameChannel }}</span>
                              <span class="ml-1 text-danger" v-else>#none</span><i class="far fa-pen font-12 ml-3"></i></span>
                           </h6>
                        </div>
                     </div>
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.shop != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ecommerceSettings.channelMessage") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.shop.enabled" @click="toggleMessage('channelEcommerce')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.shop.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.shop.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.send") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.shop)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                           <div class="row m-0 align-items-center mt-3" v-if="settings.nameChannel == null && preview.shop.enabled">
                              <div class="col">
                                 <div class="alert alert-danger alert-dismissible mb-0" role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                                    <i class="fa fa-times-circle"></i> {{ $t("welcomeGoodbye.joinMessage.error") }}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.shop.enabled" :message="preview.shop" :dadosUsuario="dadosUsuario" />
                  </div>

                  <div class="col-md-6 col-xl-4" v-if="preview.thanks != null">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <div class="row m-0 align-items-center">
                              <div class="col">
                                 <h6 class="limitador my-0">{{ $t("ecommerceSettings.thanks") }}</h6>
                              </div>
                              <div class="w-max-content">
                                 <label class="switch">
                                    <input type="checkbox" v-model="preview.thanks.enabled" @click="toggleMessage('thanksEcommerce')"><span class="slider"></span>
                                 </label>
                              </div>
                              <div class="w-max-content pl-1" v-if="preview.thanks.enabled">
                                 <button class="btn btn-outline-primary h-auto rounded-sm" @click="testMessage(preview.thanks.tipo, settings.idChannel)"><i class="far fa-share font-11 mr-1"></i> {{ $t("serverSettings.buttons.test") }}</button>
                              </div>
                              <div class="w-max-content pl-1">
                                 <button class="btn btn-primary h-auto rounded-sm" @click="editPreview(preview.thanks)"><i class="far fa-pen font-12 mr-1"></i> {{ $t("serverSettings.buttons.edit") }}</button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <PreviewMessageDeck class="m-0 p-0" v-show="preview.thanks.enabled" :message="preview.thanks" :dadosUsuario="dadosUsuario" />
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="three">
               <div class="row">
                  <div class="col-lg-6">
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1">
                                    <a href="https://dashboard.stripe.com/dashboard" target="_blank"><i class="far fa-globe-americas color-theme font-13 mr-2"></i> {{ $t("ecommerceSettings.stripe.title") }} <i class="far fa-external-link-alt font-10 ml-1"></i></a>
                                 </h6>
                                 <p class="text-center mb-0">{{ $t("ecommerceSettings.stripe.desc") }}</p>
                                 <hr>
                              </div>
                              <div class="col-12 px-1">
                                 <input type="text" class="form-control" v-model="settings.secretKeyStripe" :readonly="serverS.isAdmin" />
                                 <small class="d-block text-right mt-1" v-if="serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="card mb-2">
                        <div class="card-body">
                           <div class="row m-0">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-1">
                                    <a href="https://www.mercadopago.com.br/home" target="_blank"><i class="far fa-credit-card color-theme font-13 mr-2"></i> {{ $t("ecommerceSettings.mercadoPago.title") }} <i class="far fa-external-link-alt font-10 ml-1"></i></a>
                                 </h6>
                                 <p class="text-center mb-0">{{ $t("ecommerceSettings.mercadoPago.desc") }}</p>
                                 <hr>
                              </div>
                              <div class="col-12 px-1">
                                 <input type="text" class="form-control" v-model="settings.accessTokenMercadoPago" :readonly="serverS.isAdmin" />
                                 <small class="d-block text-right mt-1" v-if="serverS.isAdmin"><i class="far fa-exclamation-triangle font-10 mr-1"></i> {{ $t("serverSettings.necessaryOwner") }}</small>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="four">
               <div class="row">
                  <div class="col-lg-6">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-plus color-theme font-13 mr-1"></i> {{ $t("ecommerceSettings.webhook.addProduct") }}</label>
                           <input type="text" class="form-control" v-model="webhook.addProduct" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-pen color-theme font-11 mr-1"></i> {{ $t("ecommerceSettings.webhook.editProduct") }}</label>
                           <input type="text" class="form-control" v-model="webhook.editProduct" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-times color-theme font-13 mr-1"></i> {{ $t("ecommerceSettings.webhook.deleteProduct") }}</label>
                           <input type="text" class="form-control" v-model="webhook.deleteProduct" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-star color-theme font-12 mr-1"></i> {{ $t("ecommerceSettings.webhook.signature") }}</label>
                           <input type="text" class="form-control" v-model="webhook.signature" />
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-shopping-bag color-theme font-12 mr-1"></i> {{ $t("ecommerceSettings.webhook.orderPlaced") }}</label>
                           <input type="text" class="form-control" v-model="webhook.orderPlaced" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="fal fa-credit-card color-theme font-12 mr-1"></i> {{ $t("ecommerceSettings.webhook.paymentMade") }}</label>
                           <input type="text" class="form-control" v-model="webhook.paymentMade" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-plus color-theme font-13 mr-1"></i> {{ $t("ecommerceSettings.webhook.addCategory") }}</label>
                           <input type="text" class="form-control" v-model="webhook.addCategory" />
                        </div>
                     </div>
                     <div class="card mb-2">
                        <div class="card-body p-3">
                           <label><i class="far fa-times color-theme font-13 mr-1"></i> {{ $t("ecommerceSettings.webhook.deleteCategory") }}</label>
                           <input type="text" class="form-control" v-model="webhook.deleteCategory" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tab-pane" id="five">
               <div class="card mb-0">
                  <div class="card-body">
                     <EditorHTML :value="settings.termsPurchase" @attValue="settings.termsPurchase = $event" />
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <!-- modalChannel -->
      <div class="modal fade" id="modalChannel" tabindex="-1" aria-labelledby="modalChannelLabel" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalChannelLabel">{{ $t("serverSettings.selectChannel.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-12 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="col-12 px-1 mb-1">
                                 <h6 class="text-center mb-0">{{ $t("serverSettings.selectChannel.sub") }}</h6>
                                 <hr>
                              </div>
                              <div class="col-12 px-1 mb-1">
                                 <div class="cards">
                                    <channel v-for="(channel, index) in channelsList" :key="index" :channel="channel" :selected="settings.idChannel" @selectChannel="saveChannel($event)" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.selectChannel.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalPreview -->
      <div class="modal fade" id="modalPreview" tabindex="-1" aria-labelledby="modalPreviewLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalPreviewLabel">{{ $t("serverSettings.editMessage.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0" v-if="message != null">
                     <div class="class_preview">
                        <PreviewMessageDeck :message="message" :dadosUsuario="dadosUsuario" class="class_preview_div" />
                     </div>
                     <div class="class_message">
                        <MessageDeck :message="message" @attObjetoMessage="attObjetoMessage($event)" />
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePreview">{{ $t("serverSettings.editMessage.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.editMessage.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>

      <button class="btn btn-primary btn-float" @click="saveSettings" v-if="showSave"><i class="fal fa-save"></i></button>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import EditorHTML from '@/components/EditorHTML.vue'
import MessageDeck from '@/components/MessageDeck.vue'
import channel from '@/components/whitelist/Channel.vue'
import category from '@/components/ecommerce/Category.vue'
import UploadImageDeck from '@/components/UploadImageDeck.vue'
import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
   name: 'Ecommerce_settings',
   data: function () {
		return {
         message: null,
         showSave: false,
         preview: {'shop': null, 'thanks': null},
         settings: {
            'idPermission': null, 'namePermission': null, 'permission': null, 'idChannel': null, 'nameChannel': null, 'idServer': null, 'categories': [], 'secretKeyStripe': null, 
            'accessTokenMercadoPago': null, 'shopName': null, 'shopIcon': null, 'shopURL': null, 'banner1': null, 'banner2': null, 'banner3': null, 'termsPurchase': null
         },
         webhook: {'idServer': null, 'addProduct': null, 'editProduct': null, 'deleteProduct': null, 'orderPlaced': null, 'paymentMade': null, 'addCategory': null, 'deleteCategory': null, 'signature': null},
         category: {"toggle": false, "name": null}
      }
   },
   computed: {
		...mapState({
         permissionsList: state => state.permissionsList,
         dadosUsuario: state => state.dadosUsuario,
         channelsList: state => state.channelsList,
         urlEcommerce: state => state.urlEcommerce,
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   watch: {
      settings: {
         handler: function (newValue, oldValue) {
            if (newValue && newValue.idServer && oldValue && oldValue.idServer) {
               this.showSave = true
            }
         },
         deep: true
      },
      webhook: {
         handler: function (newValue, oldValue) {
            if (newValue && newValue.idServer && oldValue && oldValue.idServer) {
               this.showSave = true
            }
         },
         deep: true
      }
   },
   components: {
      channel, PreviewMessageDeck, MessageDeck, category, UploadImageDeck, EditorHTML
   },
   methods: {
      getSettings : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getSettingsEcommerce',
            params: {
               idServer: this.serverS.id_s
            }
         }).then(response => {
            this.preview = response.data.preview;
            this.webhook = response.data.webhook;
            this.settings = {
               "idPermission": response.data.settings.idPermission,
               "accessTokenMercadoPago": response.data.settings.accessTokenMercadoPago,
               "idChannel": response.data.settings.idChannel,
               "shopName": response.data.settings.shopName,
               "shopURL": response.data.settings.shopURL,
               "shopIcon": response.data.settings.shopIcon,
               "banner1": response.data.settings.banner1,
               "banner2": response.data.settings.banner2,
               "banner3": response.data.settings.banner3,
               "nameChannel": response.data.settings.nameChannel,
               "idServer": response.data.settings.idServer,
               "secretKeyStripe": response.data.settings.secretKeyStripe,
               "id": response.data.settings.id,
               "categories": response.data.settings.categories,
               "termsPurchase": response.data.settings.termsPurchase,
               "namePermission": response.data.settings.namePermission,
               "permission": this.permissionsList.find(permission => permission.id == response.data.settings.idPermission),
               "showSave": false
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editChannel : function () {
         $('#modalChannel').modal('show')
      },
      saveChannel : function (channel) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.settings.idChannel = channel.id;
         this.settings.nameChannel = channel.name;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/alterChannelEcommerce',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            $('#modalChannel').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.saved')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      addCategory : function () {
         if (this.category.name == null || String(this.category.name).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t('serverSettings.status.invalid')
            })

            return
         }

         this.settings.categories.push(this.category.name)
         this.category = {"toggle": false, "name": null}
      },
      removeCategory : function (index) {
         this.settings.categories.splice(index, 1)
      },
      saveSettings : function (permission) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         if (permission && permission.id) {
            this.settings.idPermission = permission.id;
            this.settings.namePermission = permission.name;
         } else {
            this.settings.idPermission = this.settings.permission.id
            this.settings.namePermission = this.settings.permission.name
            
            if (this.settings.shopName == null || String(this.settings.shopName).trim().length == 0 ||
               this.settings.shopURL == null || String(this.settings.shopURL).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: this.$t('ecommerceSettings.fillAllFields')
               })
   
               return
            }
         }

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveSettingsEcommerce',
            headers: {
					'Content-Type': 'application/json'
            },
				data: {'settings': this.settings, 'webhook': this.webhook}

         }).then(() => {
            let attServer = this.serverS;
            attServer.idPermissionEcommerce = this.settings.idPermission;
            this.$store.dispatch('updateServerS', attServer)

            this.showSave = false

            this.$toast.fire({
               icon: 'success',
               title: this.$t('ecommerceSettings.savedSettings')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: this.$t("ecommerceSettings.nameAlreadyUsed")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      editPreview : function (preview) {
         this.message = null;
         
         setTimeout(() => {
            this.message = JSON.parse(JSON.stringify(preview));
            $('#modalPreview').modal('show')
         }, 50);
      },
      attObjetoMessage : function(e) {
			this.message = e;
		},
      savePreview : function () {
         this.$store.dispatch('saveMessage', this.message).then(() => {
            $('#modalPreview').modal('hide')

				this.getSettings()
            this.message = null;

            this.$toast.fire({
               icon: 'success',
               title: this.$t('serverSettings.swal.message')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      },
      toggleMessage : function (type) {
         let value = null;

         setTimeout(() => {
            switch (type) {
               case "thanksEcommerce":
                  value = this.preview.thanks.enabled
               break;
               case "channelEcommerce":
                  value = this.preview.shop.enabled
               break;
            }

            this.$store.dispatch('toggleMessage', {type: type, value: value}).then(() => {
               this.$toast.fire({
                  icon: 'success',
                  title: this.$t("serverSettings.swal.message")
               })
            }).catch((error) => {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     this.$store.dispatch('deslogar')

                     this.$toast.fire({
                        icon: 'warning',
                        title: this.$t("serverSettings.swal.expired")
                     });
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: 'Error: ' + error.response.status
                     });
                  }
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            })
         }, 100);
      },
      saveStyle : function (key, value) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         if (key && value) {
            this.settings[key] = value;
         }

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveStyleEcommerce',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.settings

         }).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t('ecommerceSettings.savedStyle')
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else if (error.response.status == 400) {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Invalid URL!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      testMessage : function (type, idChannel) {
         this.$store.dispatch('sendMessage', {'type': type, 'idServer': this.serverS.id_s, 'idChannel': idChannel}).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.messageSent")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('getPermissions')
      this.$store.dispatch('getChannels')
      this.getSettings()
   }
}

</script>

<style scoped>

.fa-external-link-alt {
   transform: translateY(-2px);
}

</style>