<template>
   <div class="card mb-0">
      <div class="body py-3">
         <div class="row">
            <div class="col-8 align-self-center">
               <h6 class="limitador my-0">{{ category }}</h6>
            </div>
            <div class="col text-right"><i class="far fa-trash text-danger cursor-pointer font-16" @click="remove"></i></div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Category',
   props: ['category', 'index'],
   methods: {
      remove : function () {
         this.$emit('remove', this.index)
      }
   }
}

</script>

<style scoped>

.card:hover {
   transform: scale(1.0025) !important;
   transition: all 0.1s ease !important;
}

</style>